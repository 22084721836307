import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCategory } from "../../hooks";
import { useContentContext } from "../../contexts/ContentContext";
import { useElementContext } from "../../contexts/ElementContext";

const CategoryPage = () => {
  const { id } = useParams();
  const pageData = useContentContext();
  const {
    ProductListingModule,
    PageModule: { DynamicPage },
    CommonModule: { CategoryList, ListingBanner, PageContentRow },
  } = useElementContext();
  const navigate = useNavigate();

  const { categoryRequest, categoryData, categoryListRequest, crumbCalculator, categoryRoute, isError, errorMessage } =
    useCategory();
  const { categorySearchListing, categoryHeaderStyle = "compact", categroyMetaData = {} } = pageData.templateData || {};

  if (!categorySearchListing) return <DynamicPage />;

  const { settings: { categoryHTMLTitleString } = {}, categoryName, imagePath, categoryDescription } = categoryData;

  const title = pageData?.title || categoryName;
  const pageLogo = pageData?.pageLogo || imagePath;
  const description = pageData?.description || categoryDescription;

  return (
    <DynamicPage>
      {({ renderDynamicContent }) => (
        <>
          {!!categoryHTMLTitleString && <Helmet title={categoryHTMLTitleString} />}
          <PageContentRow>
            <ListingBanner
              crumbs={crumbCalculator()}
              heading={title}
              images={[pageLogo]}
              description={description}
              type="category"
              isCollapsible={categoryHeaderStyle === "compact"}
            />
          </PageContentRow>

          {isError && (
            <div className="container bg-light box-shadow-lg rounded-lg p-5">
              <div className="row">
                <div className="alert alert-info" role="alert">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}

          {renderDynamicContent()}

          <CategoryList
            isFetching={categoryListRequest.isFetching || !categoryRequest.isLoaded}
            onSelect={(urlTitle) => {
              navigate(`/${categoryRoute}/${urlTitle}`);
            }}
            data={categoryData}
            categroyMetaData={categroyMetaData}
          />

          {(!categoryData?.children || categoryData?.children?.length === 0) && (
            <ProductListingModule.CoreComponents.SearchListing
              hide="category"
              key={id}
              {...categorySearchListing}
              initPreFilter={{
                facet_category: id,
              }}
            />
          )}
        </>
      )}
    </DynamicPage>
  );
};

export default CategoryPage;
