import { useElementContext } from "../../contexts";

const CategoryList = ({ data, onSelect, isFetching = true, categroyMetaData = {} }) => {
  const {
    CommonModule: { SimpleImage, Spinner },
  } = useElementContext();
  return (
    <>
      {isFetching && (
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <Spinner />
        </div>
      )}
      {!isFetching && data.children && data.children?.length > 0 && (
        <div className="container pb-4 pb-sm-5">
          <div className="row pt-5">
            {data.children
              .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
              .map((cat) => {
                const cmsCategoryData = categroyMetaData[cat.urlTitle];
                const categoryName = cmsCategoryData.categoryName || cat.categoryName;
                const categoryImage = cmsCategoryData.categoryLogo || cat.imageFile;
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" key={cat.categoryID}>
                    <div className="card border-0">
                      <div
                        className="d-block overflow-hidden rounded-lg"
                        onClick={(e) => {
                          e.preventDefault();
                          onSelect(cat.urlTitle);
                        }}
                      >
                        <SimpleImage className="d-block w-100" src={categoryImage} alt={categoryName} type="category" />
                      </div>
                      <div className="card-body">
                        <h4 className="categoryList-title">
                          <button
                            className="link-button"
                            onClick={(e) => {
                              e.preventDefault();
                              onSelect(cat.urlTitle);
                            }}
                          >
                            {categoryName}
                          </button>
                        </h4>
                        <ul className="list-unstyled font-size-sm mb-0">
                          {cat.children
                            .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
                            .map((cat) => {
                              const cmsCategoryData = categroyMetaData[cat.urlTitle];
                              const categoryName = cmsCategoryData.categoryName || cat.categoryName;
                              return (
                                <li className="d-flex align-items-center justify-content-between" key={cat.categoryID}>
                                  <button
                                    className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onSelect(cat.urlTitle);
                                    }}
                                  >
                                    <i className="bi bi-chevron-circle-right pr-2"></i>
                                    {categoryName}
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
export { CategoryList };
